import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	leftText: {
		float: 'left',
		fontSize: 15,
		fontWeight: 600,
		color: '#464749'
	},
	leftTextLarge: {
		float: 'left',
		fontSize: 20,
		fontWeight: 600,
		color: '#464749'
	},
	rightLink: {
		float: 'right',
		fontSize: 12,
		fontWeight: 600,
		color: '#0087ce',
		textDecoration: 'underline',
		cursor: 'pointer'
	}
}));
