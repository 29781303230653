import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { Link, navigate } from 'gatsby';
import useStyles, { accordionStyles, menuStyle } from './styles';
import { useScreenClass } from 'react-grid-system';
import Arrow from '../../../assets/icons/Arrow_right.svg';
import RVlogo from '../../../assets/images/RV_logo.png';
import { slide as HamburgerMenu } from 'react-burger-menu';
import MenuAccordion from '../MenuAccordion';
import { getMenuPaths } from '../../../utils/helpers/index';
import { ROLE_RV_EQUINE_WELFARE } from '../../../utils/constants';
import { DASHBOARD_ROUTE } from '../../../utils/constants/routes';

const Menu = ({ backArrow, backArrowClick }) => {
	const classes = useStyles();
	const screenClassForRender = useScreenClass();
	const [menuState, setMenuState] = useState(false);
	const auth = useAuth();
	const [headerLinks, setHeaderLinks] = useState([]);

	const closeMenu = () => {
		setMenuState(false);
	};

	useEffect(() => {
		const isUserLoggedIn = auth?.currentUser != null;
		const isAdmin =
			auth?.currentUser != null &&
			auth?.currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE);
		const headerLinks = getMenuPaths(
			isUserLoggedIn,
			isAdmin,
			auth?.currentUser,
			auth?.signOut
		);
		setHeaderLinks(headerLinks);
	}, [auth?.currentUser]);

	const StandardLink = ({ link }) => {
		return (
			<div key={link.label} className={classes.menuItem}>
				<Link
					className={
						link.styling ? classes[link.styling] : classes.standardLink
					}
					to={link.to}
					onClick={() => {
						closeMenu();
						if (link.onClick) {
							link.onClick();
						}
					}}
				>
					{link.label}
				</Link>
			</div>
		);
	};

	const AccordionLinks = ({ links }) => {
		return (
			<MenuAccordion title={links.label} styles={accordionStyles}>
				<div className={classes.dropDown}>
					{links.dropdownItems
						.filter((m) => m.show)
						.map((link) => (
							<Link
								key={link.label}
								className={classes.dropdownLink}
								to={link.to}
								onClick={() => {
									closeMenu();
									if (link.onClick) {
										link.onClick();
									}
								}}
								state={link.state}
							>
								{link.label}
							</Link>
						))}
				</div>
			</MenuAccordion>
		);
	};

	const noAction = () => {};
	return (
		<div
			className={classes.container}
			style={{
				display: ['xxl', 'xl', 'lg'].includes(screenClassForRender)
					? 'none'
					: ''
			}}
		>
			<div className={classes.menuBarContainer}>
				<div
					onClick={backArrow ? backArrowClick : () => noAction()}
					className={classes.arrow}
					style={backArrow ? {} : { visibility: 'hidden' }}
				>
					<img src={Arrow} className={classes.backArrow} />
				</div>
				<img
					src={RVlogo}
					className={classes.logo}
					onClick={() => {
						closeMenu();
						navigate(DASHBOARD_ROUTE);
					}}
				/>
				<div className={classes.hamburger}>
					<HamburgerMenu
						isOpen={menuState}
						onStateChange={(state) => setMenuState(state.isOpen)}
						styles={menuStyle}
						width={'100%'}
						right
					>
						{headerLinks
							.filter((link) => link.show)
							.map((link) => (
								<div key={link.label}>
									{link.isDropDown === true ? (
										<AccordionLinks links={link} />
									) : (
										<StandardLink link={link} />
									)}
								</div>
							))}
					</HamburgerMenu>
				</div>
			</div>
		</div>
	);
};

export default Menu;
