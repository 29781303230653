import { createUseStyles } from 'react-jss';

export default createUseStyles({
	container: {
		display: 'flex',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		marginBottom: 30,
		'@media (min-width:992px)': {
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'center',
			paddingTop: 10
		}
	},
	linkContainer: {
		width: '32%',
		position: 'relative',
		cursor: 'pointer',
		borderRadius: 4,
		overflow: 'hidden',
		'@media (min-width:992px)': {
			minWidth: 200,
			marginBottom: 15
		}
	},
	links: {
		background: '#dcebf3',
		color: '#006da6',
		textAlign: 'center',
		height: 40,
		fontSize: 13,
		fontWeight: 600,
		width: '100%',
		boxSizing: 'border-box',
		position: 'absolute',
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		whiteSpace: 'normal',
		'@media (min-width:992px)': {
			height: 50,
			padding: 10
		}
	},
	linkText: {
		width: '100%',
		wordBreak: 'break-word',
		fontSize: 12,
		paddingLeft: 5,
		paddingRight: 5,
		'@media (min-width:992px)': {
			paddingLeft: 0,
			paddingRight: 0,
			fontSize: 15
		}
	},
	linkImage: {
		width: '100%',
		objectFit: 'cover',
		height: 115,
		'@media (min-width:992px)': {
			height: 150
		}
	}
});
