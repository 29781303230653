import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'react-grid-system';
import { useAuth } from '../../hooks/useAuth';
import useStyles from './styles';
import RVlogo from '../../assets/images/RV_logo.png';
import { ScreenClassRender } from 'react-grid-system';
import { getMenuPaths } from '../../utils/helpers/index';
import { ROLE_RV_EQUINE_WELFARE } from '../../utils/constants';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';

const Header = () => {
	const classes = useStyles();
	const auth = useAuth();

	const [headerLinks, setHeaderLinks] = useState([]);
	const [isScrolling, setIsScrolling] = useState(false);
	useEffect(() => {
		const isUserLoggedIn = auth?.currentUser != null;
		const isAdmin =
			auth?.currentUser != null &&
			auth?.currentUser?.Roles.includes(ROLE_RV_EQUINE_WELFARE);
		const headerLinks = getMenuPaths(
			isUserLoggedIn,
			isAdmin,
			auth?.currentUser,
			auth?.signOut
		);
		setHeaderLinks(headerLinks);
	}, [auth?.currentUser]);

	useEffect(() => {
		const onScrollListener = () => {
			if (window.pageYOffset > 0) {
				setIsScrolling(true);
			} else {
				setIsScrolling(false);
			}
		};
		window.addEventListener('scroll', onScrollListener);
		return () => window.removeEventListener('scroll', onScrollListener);
	}, []);

	const DropDownLinks = ({ links }) => {
		return (
			<div className={classes.dropDown}>
				<div className={classes.dropDownPlaceholder}>
					<div id={links.id} className={classes.dropDownHeader}>
						{links.label}
					</div>
				</div>
				<div className={classes.dropdownContent}>
					{links.dropdownItems
						.filter((m) => m.show)
						.map((link) => (
							<Link
								id={link.id}
								key={link.label}
								className={classes.dropdownLink}
								to={link.to}
								state={link.state}
								onClick={link.onClick}
							>
								{link.label}
							</Link>
						))}
				</div>
			</div>
		);
	};

	const StandardLink = ({ link }) => {
		return (
			<div key={link.label} className={classes.menuItem}>
				<Link
					className={
						link.styling ? classes[link.styling] : classes.siteHeaderMenuItem
					}
					id={link.id}
					to={link.to}
					onClick={link.onClick}
				>
					{link.label}
				</Link>
			</div>
		);
	};

	const desktopComponent = (
		<div
			className={classes.stickyHeader}
			style={{
				...(isScrolling && { borderBottom: '1px solid #dcebf3' })
			}}
		>
			<Container className={classes.topHeader}>
				<Row style={{ margin: 0, height: '100%' }}>
					<Col md={12} lg={2} className={classes.mobileHeaderContainer}>
						<Link to={DASHBOARD_ROUTE} className={classes.logoLink}>
							<img
								src={RVlogo}
								className={classes.logo}
								onClick={() => {
									navigate(DASHBOARD_ROUTE);
								}}
							/>
						</Link>
					</Col>
					<Col md={12} lg={10} className={classes.headerContainer}>
						{headerLinks
							.filter((link) => link.show)
							.map((link) => (
								<div key={link.label}>
									{link.isDropDown === true ? (
										<DropDownLinks links={link} />
									) : (
										<StandardLink link={link} />
									)}
								</div>
							))}
					</Col>
				</Row>
			</Container>
		</div>
	);

	const mobileComponent = <></>;

	const page = (screenClass) => {
		return ['xs', 'sm', 'md'].includes(screenClass)
			? mobileComponent
			: desktopComponent;
	};

	return <ScreenClassRender render={page} />;
};

Header.propTypes = {
	siteTitle: PropTypes.string
};

Header.defaultProps = {
	siteTitle: ``
};

export default Header;
