import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
  buttonStyle: {
    background: "#dcebf3",
    color: "#006da6",
    textAlign: "center",
    padding: 11,
    borderRadius: 3,
    fontSize: 13,
    fontWeight: 600,
    cursor: 'pointer'
  },
}));
