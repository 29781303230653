import React from "react";
import useStyles from "./styles";

const FormFieldMobile = ({
  label,
  isRequired,
  value,
  onChange,
  fixedText,
}) => {
  const classes = useStyles();

  return (
    <>
      {label && <div className={classes.label}>
        {label}
        {isRequired ? "*" : ""}
      </div>}
      {!fixedText && <input
        type="text"
        className={classes.inputField}
        onChange={e => onChange(e.target.value)}
        value={value}
      ></input>}
      {fixedText && <div className={classes.fixedText}>{value}</div>}
    </>
  );
};

export default FormFieldMobile;
