import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
  mobileContainer: {
    background: "#fcf7da",
    display: "flex",
    padding: "5px 10px 5px 10px",
    fontSize: 13,
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 10,
  },
  topContainer: {
    background: "#fcf7da",
    display: "flex",
    padding: "15px 10px 15px 20px",
    fontSize: 15,
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 10,
  },
  leftText: {
    width: "75%", padding: "5px 0px 5px 0px" 
  },
  rightContentMobile: {
    width: "30%",
    textAlign: "right",
    marginTop: 3
  },
  rightContent: {
    width: "30%",
    textAlign: "right",
    marginTop: 7
  },
}));
