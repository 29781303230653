import React from "react";
import Modal from "react-modal";

const buttonStyle = {
  color: "white",
  background: "#006da6",
  marginTop: 20,
  width: "35%",
  textAlign: "center",
  height: 30,
  paddingTop: 8,
  marginLeft: "auto",
  marginRight: "auto",
  cursor: "pointer",
};

const MobileModal = ({ heading, children, styles, showModal, closeModal, positiveButton }) => {
  return (
    <div>
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        style={styles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <h2>{heading}</h2>
        {children}
        <div style={buttonStyle} onClick={closeModal}>
          {positiveButton}
        </div>
      </Modal>
    </div>
  );
};

export default MobileModal;
