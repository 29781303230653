import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	primaryButton: {
		fontFamily: 'Open-sans !important',
		'&:disabled': {
			backgroundColor: '#FFFFFF',
			border: '2px solid #dcebf3',
			color: '#909195',
			'&:hover': {
				backgroundColor: '#FFFFFF',
				border: '2px solid #dcebf3',
				color: '#909195',
				cursor: 'default'
			}
		},
		width: '100%',
		minHeight: '40px',
		marginTop: '10px',
		borderRadius: '3px',
		fontWeight: 600,
		outline: 'none',
		color: '#fff',
		backgroundColor: '#006da6',
		border: 'none',
		fontSize: '15px',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#005e8f'
		}
	},
	secondaryButton: {
		extend: 'primaryButton',
		backgroundColor: '#DCEBF3',
		color: '#006DA6',
		'&:hover': {
			backgroundColor: '#DCEBF3'
		}
	},
	loadingDiv: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}));
