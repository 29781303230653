import { createUseStyles } from "react-jss";


export default createUseStyles(() => ({
  inputField: {
    width: "100%",
    height: 38,
    border: "1px solid #ccd2d5",
    borderRadius: 4,
    paddingLeft: 16,
    boxSizing: "border-box",
    color: "#464749",
    "&:focus" : {
      outline: "none"
    }
  },
  label: {
    fontSize: 13,
    color: "#464749",
    marginBottom: 5,
    fontWeight: 600,
    marginTop: 15
  },
  topBannerDefault: {
    borderRadius: "5px 5px 0px 0px",
  },
  topBannerText: {
    padding: 20,
  },
  fixedText: {
    fontSize: 13
  }
}));
