import React from 'react';
import useStyles from './styles';
const OTTNewsLink = ({ imageLink, onClick, heading, date, isMobile }) => {
	const classes = useStyles();
	return (
		<div
			className={isMobile ? classes.container : classes.smallContainer}
			onClick={onClick}
		>
			<div
				className={
					isMobile ? classes.newsContainer : classes.newsContainerLarge
				}
			>
				<div className={classes.heading}>{heading}</div>
				<div className={classes.date}>{date}</div>
			</div>
			<div className={isMobile ? classes.newsImage : classes.newsImageSmall}>
				<img
					src={imageLink}
					className={isMobile ? classes.imageFit : classes.imageFitSmall}
				></img>
			</div>
			<div className={classes.clear}></div>
		</div>
	);
};

export default OTTNewsLink;
