import React from 'react';
import useStyles from './styles';
import { Row, Col } from 'react-grid-system';
const OTTNews = ({
	id,
	imageLink,
	onClick,
	heading,
	body,
	date,
	isMobile,
	tags,
	headingStyle,
	isTackRoom,
	isSearchActive
}) => {
	const classes = useStyles();

	const renderTags = () => {
		return tags.map((t) => {
			return isSearchActive ? (
				<div key={t} dangerouslySetInnerHTML={{ __html: t }}></div>
			) : (
				<div key={t}>{t}</div>
			);
		});
	};

	return (
		<div
			className={
				isMobile
					? classes.container
					: isSearchActive
					? classes.smallContainerSearch
					: classes.smallContainer
			}
			onClick={onClick}
		>
			{isSearchActive ? (
				<div>
					<div
						className={
							body
								? classes.summaryContainerSearch
								: classes.noBodysummaryContainerSearch
						}
						id={id}
						onClick={onClick}
						style={
							isMobile
								? {
										marginBottom: 60
								  }
								: { marginBottom: 20 }
						}
					>
						<Row>
							<Col sm={12} lg={7}>
								<div
									className={classes.summaryHeading}
									style={headingStyle}
									dangerouslySetInnerHTML={{ __html: heading }}
								></div>
								{body && (
									<div
										className={classes.summaryBody}
										dangerouslySetInnerHTML={{
											__html: body
										}}
									></div>
								)}
								<div className={classes.summaryDate}>{date}</div>
								{tags && <div className={classes.tags}>{renderTags()}</div>}
							</Col>
							<Col sm={12} lg={5}>
								<img
									src={imageLink}
									className={
										isMobile
											? classes.newsImage
											: isTackRoom
											? classes.tackRoomImage
											: classes.newsImageSmall
									}
								/>
							</Col>
						</Row>
					</div>
				</div>
			) : (
				<div>
					<img
						src={imageLink}
						className={
							isMobile
								? classes.newsImage
								: isTackRoom
								? classes.tackRoomImage
								: classes.newsImageSmall
						}
					/>
					<div
						className={
							body ? classes.summaryContainer : classes.noBodysummaryContainer
						}
						id={id}
						onClick={onClick}
						style={
							isMobile
								? {
										marginBottom: 60
								  }
								: { marginBottom: 20 }
						}
					>
						<div className={classes.summaryHeading} style={headingStyle}>
							{heading}
						</div>
						{body && (
							<div
								className={classes.summaryBody}
								dangerouslySetInnerHTML={{
									__html: body
								}}
							></div>
						)}
						<div className={classes.summaryDate}>{date}</div>
						{tags && <div className={classes.tags}>{renderTags()}</div>}
					</div>
				</div>
			)}
		</div>
	);
};
export default OTTNews;
