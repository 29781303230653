import { createUseStyles } from 'react-jss';
// Note: Added open sans for now and these may change once the UI style guide is finalised.
import OpenSans from '../../assets/fonts/open-sans/OpenSans-Regular.ttf';
import OpenSansBold from '../../assets/fonts/open-sans/OpenSans-Bold.ttf';
import OpenSansSemiBold from '../../assets/fonts/open-sans/OpenSans-Semibold.ttf';

//TODO: Add theme and theme providers

export default createUseStyles(() => ({
	container: {
		paddingLeft: 0,
		paddingRight: 0,
		'@media (min-width:992px)': {
			paddingTop: 80
		}
	},
	pushDown: {
		marginTop: 56
	},
	'@global': {
		'html,body': {
			margin: 0,
			padding: 0,
			fontSize: 16,
			fontFamily: 'Open-sans !important',
			'-webkit-font-smoothing': 'antialiased',
			'-moz-osx-font-smoothing': 'grayscale',
			background: '#ffffff',
			color: '#0f0f0f'
		},
		input: {
			fontFamily: 'Open-sans !important',
			'-webkit-font-smoothing': 'antialiased',
			'-moz-osx-font-smoothing': 'grayscale'
		},
		'.rbc-row-segment': {
			padding: '2px 5px'
		},
		'.rbc-date-cell': {
			textAlign: 'left',
			paddingLeft: 5,
			paddingRight: 0
		},
		'.rbc-month-view': {
			border: '2px solid rgb(220 235 243)'
		},
		'.rbc-month-row + .rbc-month-row': {
			borderTop: '2px solid rgb(220 235 243)'
		},
		'.rbc-header + .rbc-header': {
			borderLeft: '2px solid rgb(220 235 243)',
			borderBottom: '2px solid rgb(220 235 243)'
		},
		'rbc-row-content-scroll-container': {
			border: '2px solid rgb(220 235 243)'
		},
		'.rbc-day-bg + .rbc-day-bg': {
			borderLeft: '2px solid rgb(220 235 243)'
		},
		'.rbc-off-range-bg': {
			background: '#FFF'
		},
		'@font-face': [
			/**
			 * Regular OpenSans Font faces
			 */
			{
				fontFamily: 'Open-sans',
				fontStyle: 'normal',
				fontWeight: 400,
				src: `url(${OpenSans})`
			},
			{
				fontFamily: 'Open-sans',
				fontStyle: 'normal',
				fontWeight: 500,
				src: `url(${OpenSansSemiBold})`
			},
			/**
			 * Bold OpenSans Font faces
			 */
			{
				fontFamily: 'Open-sans',
				fontStyle: 'normal',
				fontWeight: 600,
				src: `url(${OpenSansBold})`
			}
		],
		h2: {
			fontSize: 20,
			fontWeight: 'bold'
		}
	}
}));
