import {
	ACKNOWLEDGED_RETRAINER,
	ACKNOWLEDGED_RETIREMENT_FARMS,
	THOROUGHBRED,
	HORSE_IDENTIFICATION_POINTS_ALLOCATION,
	ROLE_RV_EQUINE_WELFARE,
	MOBILE_SCREEN_SIZES,
	MULTIPLE_HORSES,
	ROLE_COMPETITION_ADMIN,
	CommonKeys,
	ACKNOWLEDGED_FOSTER_FARMS,
	ROLE_ACKNOWLEDGED_FOSTER_CARER,
	ROLE_REHOMER
} from '../constants';
import moment from 'moment';
import { getUserImage, uploadImageToS3 } from '../../graphql/custom.queries';
import AppSyncService from '../../graphql/AppSyncService';
import {
	DASHBOARD_ROUTE,
	MY_STABLE_ROUTE,
	OTT_PARTNERSHIPS_ROUTE,
	PROGRAMS_OHEP_LANDING_PAGE_ROUTE
} from '../constants/routes';
import axios from 'axios';
import { navigate } from 'gatsby';
import * as dayjs from 'dayjs';

export const getImageSrcUrl = (s3ImageKey, stateUpdateFunc) => {
	const imageKey = { imageKey: s3ImageKey };
	AppSyncService.execute(getUserImage, imageKey).then((data) => {
		stateUpdateFunc(data.data.getUserImage.signedUrl);
	});
};

export const imageUploadS3 = (image, onUploadSuccess) => {
	const imageKey = { imageKey: image.name };
	const headers = {
		'Content-Type': image.type
	};
	AppSyncService.execute(uploadImageToS3, imageKey).then((result) => {
		if (result?.data?.putUserImage?.imageKey) {
			const imageKey = result.data.putUserImage.imageKey;
			axios
				.put(result.data.putUserImage.signedUrl, image, { headers })
				.then(() => {
					onUploadSuccess(imageKey);
				});
		}
	});
};

export const formatDate = (date) => {
	var d = new Date(date),
		month = '' + (d.getMonth() + 1),
		day = '' + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;

	return [year, month, day].join('-');
};

export const getYear = (date) => {
	var d = new Date(date),
		year = d.getFullYear();
	return year;
};

export const parseUTCToLocal = (utcTimestamp) =>
	moment.utc(utcTimestamp).local();

export const formatDateReadable = (date) => moment(date).format('DD MMM YYYY');

export const futureDateAfterDays = (date, days) => {
	var result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
};

export const formatDateReadableVariant = (date) =>
	date ? moment(date).format('DD/MM/YYYY') : '';

export const formatDateTimeReadable = (utcTimestamp) =>
	parseUTCToLocal(utcTimestamp).format('DD/MM/YYYY h:mm A');

export const formatDateCalendar = (date) =>
	date ? moment(date).format('ddd  D  MMM') : '';

export const formatDateCalendarVariant = (date) =>
	date ? moment(date).format('ddd,  Do  MMMM YYYY') : '';

export const convertUTCTimeToLocal = (utcTime) => {
	let utcDate = new Date(
		new Date().setUTCHours(
			parseInt(utcTime.substring(0, 2)),
			parseInt(utcTime.substring(3, 5)),
			parseInt(utcTime.substring(6, 8))
		)
	);
	let minutesFormatted;
	if (utcDate.getMinutes() < 10) {
		minutesFormatted = '0' + utcDate.getMinutes();
	} else {
		minutesFormatted = utcDate.getMinutes();
	}
	return utcDate.getHours() + ':' + minutesFormatted;
};

export const mapToCheckbox = (data) => {
	let checkboxData = [];
	data.map((item) => {
		checkboxData.push({
			id: item.id,
			label: item.label,
			value: item.value,
			isSelected: item.isSelected
		});
	});
	return checkboxData;
};

export const convertToCheckboxData = (id, value, label, isSelected) => {
	let obj = {};
	obj['id'] = id;
	obj['label'] = label;
	obj['value'] = value;
	obj['isSelected'] = isSelected;
	return obj;
};

export const convertToMultiListData = (listData, fieldName) => {
	let selectList = [];
	listData.map((item) => {
		let obj = {};
		obj['value'] = item.id;
		obj['label'] = fieldName == 'value' ? item.value : item.name;
		selectList.push(obj);
	});
	return selectList;
};

export const convertToSortedMultiListData = (listData) => {
	let selectList = [];
	listData
		.filter((i) => i.value != 'Other')
		.map((item) => {
			let obj = {};
			obj['value'] = item.id;
			obj['label'] = item.value;
			selectList.push(obj);
		});
	listData
		.filter((i) => i.value == 'Other')
		.map((item) => {
			let obj = {};
			obj['value'] = item.id;
			obj['label'] = item.value;
			selectList.push(obj);
		});
	return selectList;
};

export const convertToSortedMultiListDataClubs = (listData) => {
	let selectList = [];
	listData
		.filter((i) => i.name != 'Other')
		.map((item) => {
			let obj = {};
			obj['value'] = item.id;
			obj['label'] = item.name;
			selectList.push(obj);
		});
	listData
		.filter((i) => i.name == 'Other')
		.map((item) => {
			let obj = {};
			obj['value'] = item.id;
			obj['label'] = item.name;
			selectList.push(obj);
		});
	return selectList;
};

export const getMenuPaths = (isUserLoggedIn, isAdmin, currentUser, signOut) => {
	// Populate the header links
	const headerLinks = [];
	headerLinks.push({
		id: CommonKeys.IM_NEW,
		label: `I\'M NEW`,
		to: '/im-new',
		show: !isUserLoggedIn,
		styling: 'imNew'
	});
	headerLinks.push({
		id: CommonKeys.OTT_SEARCH,
		label: 'OTT SEARCH',
		isDropDown: true,
		dropdownItems: [
			{
				id: CommonKeys.SEARCH_RETIREMENT_FARMS,
				label: 'SEARCH RETIREMENT FARM',
				to: '/search',
				state: { searchSelected: true, search: ACKNOWLEDGED_RETIREMENT_FARMS },
				show: true
			},
			{
				id: CommonKeys.SEARCH_RETRAINER,
				label: 'SEARCH RETRAINER',
				to: '/search',
				state: { searchSelected: true, search: ACKNOWLEDGED_RETRAINER },
				show: true
			},
			{
				id: CommonKeys.SEARCH_FOSTER_PROVIDER,
				label: 'SEARCH STAR PROVIDER',
				to: '/search',
				state: { searchSelected: true, search: ACKNOWLEDGED_FOSTER_FARMS },
				show:
					isAdmin ||
					currentUser?.Roles?.includes(ROLE_ACKNOWLEDGED_FOSTER_CARER)
			},
			{
				id: CommonKeys.SEARCH_THOROUGHBRED,
				label: 'SEARCH THOROUGHBRED',
				to: '/search',
				state: { searchSelected: true, search: THOROUGHBRED },
				show: true
			},
			{
				id: CommonKeys.SEARCH_MULTIPLE_THOROUGHBREDS,
				label: 'SEARCH MULTIPLE THOROUGHBREDS',
				to: '/search/multiple-horses',
				state: { searchSelected: true, search: MULTIPLE_HORSES },
				show: isAdmin || currentUser?.Roles?.includes(ROLE_COMPETITION_ADMIN)
			}
		],
		show: true
	});
	headerLinks.push({
		id: CommonKeys.EVENTS,
		label: 'EVENTS',
		isDropDown: true,
		dropdownItems: [
			{
				id: CommonKeys.EVENTS_CALENDAR,
				label: 'Events Calendar',
				to: '/events',
				show: true
			},
			{
				id: CommonKeys.OTT_EVENTS_SPONSORSHIP,
				label: 'OTT Events & Sponsorship',
				to: '/ott-events',
				show: true
			},
			{
				id: CommonKeys.OTT_PARTNERSHIPS,
				label: 'OTT Partnerships',
				to: OTT_PARTNERSHIPS_ROUTE,
				show: true
			}
		],
		show: isUserLoggedIn
	});
	headerLinks.push({
		id: CommonKeys.PROGRAMS,
		label: 'PROGRAMS',
		isDropDown: true,
		dropdownItems: [
			{
				id: CommonKeys.POST_RACING_PROGRAMS,
				label: 'POST RACING PROGRAMS',
				to: '/programs',
				show: true
			},
			{
				id: CommonKeys.ACKNOWLEDGED_RETIREMENT_FARMS,
				label: 'ACKNOWLEDGED RETIREMENT FARMS',
				to: '/programs/acknowledged-retirement-farms-page',
				show: true
			},
			{
				id: CommonKeys.ACKNOWLEDGED_RETRAINERS,
				label: 'ACKNOWLEDGED RETRAINERS',
				to: '/programs/acknowledged-retrainers-landing-page',
				show: true
			},
			{
				id: CommonKeys.FULL_CIRCLE,
				label: 'FULL CIRCLE',
				to: '/programs/full-circle',
				show: true
			},
			{
				id: CommonKeys.LIFE_AFTER_RACING,
				label: 'LIFE AFTER RACING',
				to: '/programs/life-after-racing',
				show: true
			},
			{
				id: CommonKeys.ONSITE_HUMANE_EUTHANASIA_PROGRAM,
				label: 'Onsite Humane Euthanasia Program',
				to: PROGRAMS_OHEP_LANDING_PAGE_ROUTE,
				show: true
			},
			{
				id: CommonKeys.RESET,
				label: 'RESET',
				to: '/programs/reset-landing-page',
				show: true
			},
			{
				id: CommonKeys.SAFETY_NET_PROGRAM,
				label: 'SAFETY NET PROGRAM',
				to: '/programs/safety-net-program',
				show: true
			},
			{
				id: CommonKeys.PROGRAM_EVENTS,
				label: 'EVENTS',
				to: '/ott-events',
				show: !isUserLoggedIn
			},

			{
				id: CommonKeys.OTT_PARTNERSHIPS,
				label: 'OTT Partnerships',
				to: OTT_PARTNERSHIPS_ROUTE,
				show: !isUserLoggedIn
			}
		],
		show: true
	});
	headerLinks.push({
		id: CommonKeys.NEWS_TACK_ROOM,
		label: 'News & Tack Room',
		isDropDown: true,
		dropdownItems: [
			{
				id: CommonKeys.NEWS,
				label: 'News',
				to: '/ott-news-reel/news',
				show: true
			},
			{
				id: CommonKeys.OTT_TACK_ROOM,
				label: 'OTT Tack Room',
				to: '/ott-tack-room',
				show: true
			},
			{
				id: CommonKeys.COMMUNITY_CONTRIBUTORS,
				label: 'Community Contributors',
				to: '/ott-news-reel/community',
				show: isUserLoggedIn
			}
		],
		show: true
	});
	headerLinks.push({
		id: CommonKeys.LOGIN_HELPERS,
		label: 'LOGIN',
		to: '/login',
		show: !isUserLoggedIn
	});
	headerLinks.push({
		id: CommonKeys.JOIN_OTT_COMMUNITY,
		label: 'JOIN OTT COMMUNITY',
		to: '/register',
		show: !isUserLoggedIn
	});
	headerLinks.push({
		id: CommonKeys.ADMIN,
		label: 'ADMIN',
		to: '/review-list',
		show: isAdmin
	});
	headerLinks.push({
		label: currentUser ? currentUser?.Name : '',
		id: CommonKeys.USER_NAME,
		isDropDown: true,
		dropdownItems: [
			{
				id: CommonKeys.ACCOUNT,
				label: 'ACCOUNT',
				to: '/account',
				show: isUserLoggedIn
			},
			{
				id: CommonKeys.DASHBOARD,
				label: 'DASHBOARD',
				to: DASHBOARD_ROUTE,
				show: isUserLoggedIn
			},
			{
				id: CommonKeys.MY_STABLE_HELPERS,
				label: 'MY STABLE',
				to: MY_STABLE_ROUTE,
				show: isUserLoggedIn
			},
			{
				id: CommonKeys.LOGOUT,
				label: 'LOGOUT',
				to: '/login',
				show: isUserLoggedIn,
				onClick: signOut
			}
		],
		show: isUserLoggedIn
	});
	return headerLinks;
};

export const validatePostCode = (enteredPostCode) => {
	const regex = RegExp(/^\d*$/);
	const isValidPostCode =
		regex.test(enteredPostCode) && enteredPostCode.toString().length <= 4;
	return isValidPostCode;
};

export const sortArray = (array, noblank) => {
	try {
		const other = array.find((d) => d.value === 'Other');
		let filteredArray = array.filter((d) => d.value != 'Other');
		filteredArray = filteredArray.sort(function (a, b) {
			var textA = a.value.toUpperCase();
			var textB = b.value.toUpperCase();

			return textA.localeCompare(textB);
		});
		if (other) {
			filteredArray.push(other);
		}
		if (!noblank) {
			filteredArray.unshift({ id: 0, value: '' });
		}
		return filteredArray;
	} catch {
		return array;
	}
};

export const sortArrayByDate = (
	arr,
	name,
	order = 'DESC',
	hasNullDate = false
) => {
	try {
		const result = arr.sort((a, b) => {
			if (order === 'ASC') {
				if (dayjs(a[name]).isAfter(b[name])) {
					return 1;
				}
				return -1;
			} else {
				if (hasNullDate) {
					if (a[name] === null) {
						return 1;
					}
					if (b[name] === null) {
						return -1;
					}
				}
				if (!dayjs(a[name]).isAfter(b[name])) {
					return 1;
				}
				return -1;
			}
		});
		return result;
	} catch {
		return arr;
	}
};

export const calculateHorseIdentificationScore = (validationResult) => {
	const {
		validDam,
		validFoalDate,
		validMicrochip,
		validSire,
		brandLeftImagesCount,
		brandRightImagesCount,
		markingsFrontImagesCount,
		markingsSideImagesCount,
		microchipImagesCount
	} = validationResult;
	let totalScore = 0;
	totalScore +=
		validDam === true ? HORSE_IDENTIFICATION_POINTS_ALLOCATION.DAM_NAME : 0;
	totalScore +=
		validFoalDate === true
			? HORSE_IDENTIFICATION_POINTS_ALLOCATION.FOAL_YEAR
			: 0;
	totalScore +=
		validMicrochip === true
			? HORSE_IDENTIFICATION_POINTS_ALLOCATION.MICROCHIP_NUMBER
			: 0;
	totalScore +=
		validSire === true ? HORSE_IDENTIFICATION_POINTS_ALLOCATION.SIRE_NAME : 0;
	totalScore +=
		brandLeftImagesCount > 0
			? HORSE_IDENTIFICATION_POINTS_ALLOCATION.BRAND_IMAGES
			: 0;
	totalScore +=
		brandRightImagesCount > 0
			? HORSE_IDENTIFICATION_POINTS_ALLOCATION.BRAND_IMAGES
			: 0;
	totalScore +=
		markingsFrontImagesCount > 0
			? HORSE_IDENTIFICATION_POINTS_ALLOCATION.MARKINGS_PHOTO
			: 0;
	totalScore +=
		markingsSideImagesCount > 0
			? HORSE_IDENTIFICATION_POINTS_ALLOCATION.MARKINGS_PHOTO
			: 0;
	totalScore +=
		microchipImagesCount > 0
			? HORSE_IDENTIFICATION_POINTS_ALLOCATION.MICROCHIP_NUMBER
			: 0;
	return totalScore;
};

export const RESETWeeksAvailable = () => {
	let weeks = [{ id: 0, value: '' }];
	for (let i = 1; i < 51; i++) {
		weeks.push({ id: i, value: `${i}` });
	}
	weeks.push({ id: 51, value: '51+' });
	return weeks;
};

export const getMinDate = (years) => {
	let minDate = new Date();
	minDate.setFullYear(minDate.getFullYear() - years);
	return minDate;
};

export const checkOfficialHorseNameExists = (horseName) =>
	!!horseName?.trim() && horseName !== 'Unnamed';

export const addNameToUnnamedHorse = (horseName) => {
	const horseNameExists = checkOfficialHorseNameExists(horseName);
	return horseNameExists ? horseName : 'Unnamed';
};

export const getHorseDisplayName = (horseName, paddockName) => {
	const horseNameExists = checkOfficialHorseNameExists(horseName);
	const horseDisplayName = horseNameExists
		? horseName
		: paddockName || addNameToUnnamedHorse(horseName);
	return horseDisplayName;
};

export const checkDropDownValue = (options, selectedItemId, compareValue) => {
	return (
		options?.find(({ id }) => id == selectedItemId)?.value === compareValue
	);
};

export const isOptionOther = (options, selectedId) => {
	return checkDropDownValue(options, selectedId, 'Other');
};

export const isMultiSelectOptionOther = (value) => {
	let foundOther = false;
	value.map((item) => {
		if (item.label === 'Other') {
			foundOther = true;
		}
	});
	return foundOther;
};

export const checkUserAdmin = (userRoles) => {
	return userRoles && userRoles.includes(ROLE_RV_EQUINE_WELFARE);
};

export const isUserRehomer = (userRoles) => {
	return userRoles && userRoles.includes(ROLE_REHOMER);
};

export const checkNotEqualDropDownValue = (
	options,
	selectedItemId,
	compareValue
) => {
	return options.find(({ id }) => id == selectedItemId)?.value !== compareValue;
};

export const extractLookUpOptionsList = (
	result,
	optionsAlias,
	stateUpdateFunction
) => {
	if (result?.data?.[optionsAlias]) {
		stateUpdateFunction(sortArray(result.data[optionsAlias], true));
	}
};

export const extractLookUpOptions = (result, lookupOptionsAlias) => {
	let lookupOptionsList = [];
	if (result?.data?.[lookupOptionsAlias]) {
		lookupOptionsList = sortArray(result?.data?.[lookupOptionsAlias], true);
	}
	return lookupOptionsList;
};

export const extractLookUpOptionsOther = (result, lookupOptionsAlias) => {
	let lookupOptionsList = [];
	if (result?.data?.[lookupOptionsAlias]) {
		lookupOptionsList = result?.data?.[lookupOptionsAlias];
	}
	return lookupOptionsList;
};

export const updateDate = (date, updateFunction) => {
	if (date == '') {
		updateFunction(null);
	} else {
		let formattedDate = formatDate(date);
		if (formattedDate != '1970-01-01') {
			updateFunction(formattedDate);
		} else {
			updateFunction(null);
		}
	}
};

export const checkIsSmallScreen = (screenSize) => {
	return MOBILE_SCREEN_SIZES.includes(screenSize);
};

export const formatFormSummary = (raceTypeSummary) => {
	let formSummary = 'N/A';
	raceTypeSummary &&
		raceTypeSummary.forEach((item) => {
			if (item.starts === 'Total' && item.career !== '0-0-0-0') {
				formSummary = item.career;
			}
		});
	return formSummary;
};

export const formatiOSTextField = (text) => {
	return text
		.replace(/[\u2014]/g, '--') // emdash
		.replace(/[\u2022]/g, '*') // bullet
		.replace(/[\u2018\u2019]/g, "'") // smart single quotes
		.replace(/[\u201C\u201D]/g, '"'); // smart double quotes
};

export const formatToggleValue = (toggleValue) => (toggleValue ? 'Yes' : 'No');

export const shouldShowHorseProfileDetail = (
	visibility,
	isFollower,
	isAdmin,
	isOwner
) => {
	const publicVisibility = visibility?.id === 3;
	const followerVisibility = visibility?.id === 2;
	return (
		publicVisibility || (followerVisibility && isFollower) || isAdmin || isOwner
	);
};

export const formatFullCircleRecordVisibility = (
	fullCircleRecords,
	isAdminUser
) => {
	return fullCircleRecords.map((fullCircleRecord) => ({
		...fullCircleRecord,
		horse: {
			...fullCircleRecord.horse,
			ottInformation: {
				...fullCircleRecord.horse.ottInformation,
				paddockName: shouldShowHorseProfileDetail(
					fullCircleRecord?.horse?.ottInformation?.paddockNameVisibility,
					fullCircleRecord?.memberFollowsHorse,
					isAdminUser,
					fullCircleRecord?.memberHasClaimOnHorse
				)
					? fullCircleRecord?.horse?.ottInformation?.paddockName
					: null,
				profilePhoto: {
					key: shouldShowHorseProfileDetail(
						fullCircleRecord?.horse?.ottInformation?.profilePhotoVisibility,
						fullCircleRecord?.memberFollowsHorse,
						isAdminUser,
						fullCircleRecord?.memberHasClaimOnHorse
					)
						? fullCircleRecord?.horse?.ottInformation?.profilePhoto?.key
						: null
				}
			}
		}
	}));
};

export const formatFollowRecordVisibility = (followRecords, isAdminUser) => {
	return followRecords.map((followRecord) => ({
		...followRecord,
		horse: {
			...followRecord.horse,
			ottInformation: {
				...followRecord.horse.ottInformation,
				paddockName:
					followRecord?.horse?.ottInformation?.paddockName &&
					shouldShowHorseProfileDetail(
						followRecord?.horse?.ottInformation?.paddockNameVisibility,
						true,
						isAdminUser,
						followRecord?.memberHasClaimOnHorse
					)
						? followRecord?.horse?.ottInformation?.paddockName
						: null,
				profilePhoto: {
					key: shouldShowHorseProfileDetail(
						followRecord?.horse?.ottInformation?.profilePhotoVisibility,
						true,
						isAdminUser,
						followRecord?.memberHasClaimOnHorse
					)
						? followRecord?.horse?.ottInformation?.profilePhoto?.key
						: null
				}
			}
		}
	}));
};

/**
 * Navigates the user to the horse profile page for the horse identified by given horseCode
 * @param {string} horseCode - Unique id for the horse
 */
export const navigateToHorseProfile = (horseCode) =>
	navigate(`/horse-profile/${horseCode}`);

export const eventColourMap = (event) => {
	if (event?.isCancelled) {
		return '#FF5959';
	}
	switch (event?.eventDiscipline) {
		case 'Combined Training':
			return '#BAA8FF';
		case 'Dressage':
			return '#13F7FF';
		case 'Education':
			return '#00FFBF';
		case 'Horse Trials':
			return '#38CF4D';
		case 'Mounted Games':
			return '#FF85D7';
		case 'Multi':
			return '#FFC644';
		case 'Other':
			return '#FF9C3F';
		case 'Show Horse':
			return '#FF7F5B';
		case 'Polo':
			return '#53BCFF';
		case 'Show Jumping':
			return '#F0A9FF';
		case 'Trail Riding':
			return '#CCFF4B';
	}
};
export const convertBlankOptionToNull = (val) =>
	val && +val !== 0 ? +val : null;

export const isNumeric = (str) => {
	if (typeof str == 'number') return true;
	else if (typeof str != 'string') return false;
	return !isNaN(str) && !isNaN(parseFloat(str));
};

export const parseToTimeStamp = (selectedDate) =>
	`${parseUTCToLocal(selectedDate).format('YYYY-MM-DD HH:mm:ss.sss')} +0800`;

export const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const boldSearch = (str, query) => {
	const stringToBeSearch = str.toUpperCase();
	const queryToSearch = query.toUpperCase();
	const searchString = stringToBeSearch.indexOf(queryToSearch);
	if (!queryToSearch || searchString === -1) {
		return str;
	}
	const l = queryToSearch.length;
	return (
		str.substr(0, searchString) +
		'<b style="background-color: #fcf7da;"> ' +
		str.substr(searchString, l) +
		'</b>' +
		str.substr(searchString + l)
	);
};
