import { createUseStyles } from 'react-jss';
import BannerImage from '../../../assets/images/header_banner.png';

export default createUseStyles(() => ({
	topBanner: {
		background: `url(${BannerImage})`,
		backgroundSize: 'contain',
		maxWidth: '1000px',
		minWidth: '100%',
		minHeight: 60,
		marginBottom: 30,
		marginTop: 10,
		borderRadius: 5
	},
	topBannerInline: {
		borderRadius: 0
	},
	topBannerDefault: {
		borderRadius: '5px 5px 0px 0px'
	},
	topBannerText: {
		padding: 20
	}
}));
