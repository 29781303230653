import React from 'react';
import Loader from 'react-loader-spinner';
import useStyles from './styles';
import { CommonKeys } from './../../utils/constants/index';

const LoaderSpinner = ({ status, styles, height, color }) => {
	const classes = useStyles();
	const renderLoader = () => {
		if (status) {
			return (
				<div
					id={CommonKeys.LOADER_SPINNER}
					className={classes.container}
					style={styles}
				>
					<Loader
						type="Oval"
						color={color ? color : '#006da6'}
						height={height ? height : 50}
						width={50}
					/>
				</div>
			);
		} else {
			return <></>;
		}
	};

	return renderLoader();
};

export default LoaderSpinner;
