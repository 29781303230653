import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import {
	Container,
	ScreenClassProvider,
	ScreenClassRender
} from 'react-grid-system';
import useStyles from './styles';
import { useLocation } from '@reach/router';

import Header from '../Header';
import { Menu } from '../Mobile';
import { DASHBOARD_ROUTE } from '../../utils/constants/routes';

const MainContainer = ({ children }) => {
	const classes = useStyles();
	const location = useLocation();

	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<ScreenClassProvider>
			<Header siteTitle={data.site.siteMetadata?.title || `PRP`} />
			<Menu
				backArrow={location.pathname !== DASHBOARD_ROUTE}
				backArrowClick={() => window.history.back()}
			/>
			<ScreenClassRender
				render={(screenClass) =>
					['lg', 'xl', 'xxl'].includes(screenClass) ? (
						<></>
					) : (
						<div className={classes.pushDown}></div>
					)
				}
			/>
			<Container
				style={{
					paddingLeft: 0,
					paddingRight: 0,
					minHeight: 'calc( 100vh - 272px )'
				}}
				className={classes.container}
			>
				{children}
			</Container>
		</ScreenClassProvider>
	);
};

MainContainer.propTypes = {
	children: PropTypes.node.isRequired
};

export default MainContainer;
