import React from 'react';
import useStyles from './styles';
import PropTypes from 'prop-types';
import LoaderSpinner from '../LoaderSpinner';

const PrimaryButton = ({
	className,
	children,
	id,
	isNonSubmit,
	disabled,
	secondary,
	isLoading = false,
	...props
}) => {
	const classes = useStyles();
	return (
		<button
			id={id}
			disabled={disabled || isLoading}
			className={`${
				secondary ? classes.secondaryButton : classes.primaryButton
			} ${className}`}
			type={isNonSubmit ? 'button' : 'submit'}
			{...props}
		>
			{isLoading ? (
				<div className={classes.loadingDiv}>
					<LoaderSpinner height={'30px'} status={isLoading} />
				</div>
			) : (
				children
			)}
		</button>
	);
};

PrimaryButton.propTypes = {
	id: PropTypes.string.isRequired
};

export default PrimaryButton;
