import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		background: '#ededed',
		height: 85,
		marginBottom: 10,
		position: 'relative',
		borderRadius: 4,
		cursor: 'pointer'
	},
	smallContainer: {
		background: '#ededed',
		height: 65,
		marginBottom: 10,
		position: 'relative',
		borderRadius: 4,
		cursor: 'pointer'
	},
	newsImage: {
		float: 'right',
		width: '40%'
	},
	newsImageSmall: {
		float: 'right',
		width: '15%'
	},
	imageFit: {
		height: 85,
		width: '100%',
		objectFit: 'cover',
		borderRadius: '0px 4px 4px 0px'
	},
	imageFitSmall: {
		height: 65,
		width: '100%',
		objectFit: 'cover',
		borderRadius: '0px 4px 4px 0px'
	},
	newsContainer: {
		float: 'left',
		width: '53%',
		fontSize: 12,
		marginLeft: 10,
		marginTop: 10
	},
	newsContainerLarge: {
		float: 'left',
		width: '70%',
		fontSize: 12,
		marginLeft: 10,
		marginTop: 10
	},
	heading: {
		fontWeight: 600,
		color: '#464749',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis'
	},
	date: { fontSize: 12, color: '#9a9a9a', position: 'absolute', bottom: 10 },
	clear: { clear: 'both' }
}));
