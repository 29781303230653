import React, { useState } from "react";
import useStyles from "./styles";
import DropdownDown from "../../../assets/icons/Dropdown_Down_icon.svg";
import DropdownUp from "../../../assets/icons/Dropdown_Up_icon.svg";

const MenuAccordion = ({ children, title, styles, isOpen }) => {
  const [listOpen, setListOpen] = useState(isOpen ? true : false);
  const classes = useStyles();

  const renderAccordion = () => {
      return (
        <>
        <div
          className={classes.listToggleContainerMobile}
          onClick={() => setListOpen(!listOpen)}
          style={styles}
        >
          <div className={classes.listTitleMobile}>
            {title}
          </div>
          <div className={classes.dropDownMobile}>
            {!listOpen && <img src={DropdownDown} />}{" "}
            {listOpen && <img src={DropdownUp} />}
          </div>
        </div>
        {listOpen && <div>{children}</div>}
      </>
      )
  }

  return renderAccordion()
};

export default MenuAccordion;
