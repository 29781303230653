import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		position: 'relative',
		height: 'auto',
		marginTop: 5,
		cursor: 'pointer'
	},
	smallContainer: {
		position: 'relative',
		height: 'auto',
		marginTop: 5,
		width: 320,
		cursor: 'pointer'
	},
	smallContainerSearch: {
		position: 'relative',
		height: 'auto',
		marginTop: 5,
		width: 700,
		cursor: 'pointer'
	},
	newsImage: {
		borderRadius: 4,
		objectFit: 'cover',
		width: '100%',
		height: '300px'
	},
	newsImageSmall: {
		width: '100%',
		borderRadius: 4,
		height: 200,
		objectFit: 'cover'
	},

	summaryContainer: {
		background: 'white',
		marginTop: '-25%',
		position: 'relative',
		zIndex: 3,
		padding: '8px 14px 20px 14px',
		marginLeft: 20,
		borderRadius: 4,
		marginRight: 20
	},
	summaryContainerSearch: {
		background: 'white',
		borderRadius: 4
	},
	noBodysummaryContainer: {
		background: 'white',
		marginTop: '-15%',
		position: 'relative',
		zIndex: 3,
		padding: '8px 14px 20px 14px',
		margin: '0px 15px',
		borderRadius: 4
	},
	noBodysummaryContainerSearch: {
		background: 'white',
		borderRadius: 4
	},
	summaryHeading: {
		fontWeight: 600,
		fontSize: 12,
		marginBottom: 10,
		color: '#464749',
		wordBreak: 'break-word'
	},
	summaryBody: {
		fontSize: 12,
		height: 'auto',
		wordBreak: 'break-word',
		minHeight: 50
	},
	summaryDate: { marginTop: 10, fontSize: 12, color: '#9a9a9a' },
	tags: {
		'& div': {
			display: 'inline-block',
			background: '#ededed',
			padding: 5,
			fontSize: 12,
			marginTop: 10,
			marginRight: 10
		}
	},
	tackRoomImage: {
		width: '100%',
		borderRadius: 4,
		height: 225,
		objectFit: 'cover'
	}
}));
