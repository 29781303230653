import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
	container: {
		position: 'fixed',
		top: 0,
		zIndex: 99,
		background: 'white',
		height: 55,
		width: '100%',
		textAlign: 'center',
		borderBottom: '1px solid #deecf4'
	},
	menuBarContainer: {
		display: 'flex',
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		marginLeft: 20,
		marginRight: 20
	},
	arrow: {
		position: 'fixed',
		paddingTop: 17
	},
	pageTitle: {
		paddingTop: 20,
		fontSize: 13,
		fontWeight: 600,
		color: '#464749',
		paddingBottom: 18,
		margin: 'auto'
	},
	hamburger: { paddingTop: 17 },
	backArrow: {
		'-webkitTransform': 'scaleX(-1)',
		transform: 'scaleX(-1)'
	},
	navLink: {
		color: 'blue',
		'& a': {
			textDecoration: 'none',
			fontSize: 13,
			color: '#0085ca'
		}
	},
	logo: {
		height: 30,
		marginTop: 13,
		imageRendering: '-webkit-optimize-contrast',
		margin: 'auto'
	},
	dropDown: {
		position: 'relative',
		marginLeft: 25,
		width: 'available',
		'min-width': 'fill-available'
	},
	dropdownLink: {
		padding: '12px 16px',
		fontSize: '13px',
		display: 'inline-block',
		textTransform: 'uppercase',
		textDecoration: 'none',
		color: '#0085ca',
		width: 'available',
		'min-width': 'fill-available',
		textAlign: 'left',
		borderLeft: '1px solid #dcebf3'
	},
	menuItem: {
		color: '#0085ca',
		width: 'available',
		'min-width': 'fill-available',
		textTransform: 'uppercase',
		textDecoration: 'none',
		float: 'left',
		height: 40
	},
	standardLink: {
		float: 'left',
		margin: '12px 0px 0px 20px',
		fontSize: 13,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: '#0085ca',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
		textDecoration: 'none'
	},
	imNew: {
		float: 'left',
		margin: '12px 0px 0px 20px',
		fontSize: 13,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		color: '#0085ca',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
		textDecoration: 'none',
		background: '#e0eef4',
		padding: '2px 10px 2px 10px',
		borderRadius: 2
	}
}));

export const menuStyle = {
	/* Position and sizing of burger button */
	bmBurgerButton: {
		position: 'fixed',
		width: '18px',
		height: '15px',
		right: '25px',
		top: '22px'
	},
	/* Color/shape of burger icon bars */
	bmBurgerBars: {
		background: '#0085ca'
	},
	/* Color/shape of burger icon bars on hover*/
	bmBurgerBarsHover: {
		background: '#a90000'
	},
	/* Position and sizing of clickable cross button */
	bmCrossButton: {
		height: '20px',
		width: '20px'
	},
	/* Color/shape of close button cross */
	bmCross: {
		background: '#bdc3c7'
	},
	/*
	Sidebar wrapper styles
	Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
	*/
	bmMenuWrap: {
		position: 'fixed',
		height: '100%',
		top: 56
	},
	/* General sidebar styles */
	bmMenu: {
		background: 'white',
		padding: '10px',
		fontSize: '1.15em'
	},
	/* Morph shape necessary with bubble or elastic */
	bmMorphShape: {
		fill: 'white'
	},
	/* Wrapper for item list */
	bmItemList: {
		color: 'black',
		padding: '0.8em',
		height: '70%'
	},
	/* Individual item */
	bmItem: {
		outline: 'none',
		minHeight: 40
	},
	/* Styling of overlay */
	bmOverlay: {
		background: 'none'
	}
};

export const accordionStyles = {
	backgroundColor: 'white',
	borderLeft: 1,
	color: '#0085ca',
	fontSize: 13
};
