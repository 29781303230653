import React from 'react';
import useStyles from './styles';

const QuickLink = ({ imgSrc, linkText, onClick }) => {
	const classes = useStyles();
	return (
		<div className={classes.linkContainer} onClick={onClick}>
			<div>
				<img src={imgSrc} className={classes.linkImage} />
			</div>
			<div className={classes.links}>
				<div className={classes.linkText}>{linkText}</div>
			</div>
		</div>
	);
};

const QuickLinks = ({ links }) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			{links.map(({ id, title, image, link }) => (
				<QuickLink key={id} imgSrc={image} linkText={title} onClick={link} />
			))}
		</div>
	);
};

export default QuickLinks;
