import { createUseStyles } from "react-jss";

export default createUseStyles(() => ({
  listToggleContainerMobile: {
    background: '#0085ca',
    height: 40,
    cursor: "pointer"
  },
  listTitleMobile: {
    float: "left",
    margin: "12px 0px 0px 20px",
    fontSize: 13,
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#0085ca",
    whiteSpace: "nowrap",
    textTransform: "uppercase",
    textDecoration: "none",
  },
  dropDownMobile: {
    float: "right",
    margin: "11px 30px 0px",
  },
}));
