import React from 'react';
import useStyles from './styles';
const TopContent = ({
	leftId,
	rightId,
	leftText,
	RightLinkText,
	onClickRight,
	styles,
	isMobile,
	textLeftStyle
}) => {
	const classes = useStyles();
	return (
		<div style={styles}>
			<div
				id={leftId}
				style={textLeftStyle}
				className={isMobile ? classes.leftText : classes.leftTextLarge}
			>
				{leftText}
			</div>
			<div id={rightId} className={classes.rightLink} onClick={onClickRight}>
				{RightLinkText}
			</div>
			<div style={{ clear: 'both' }} />
		</div>
	);
};

export default TopContent;
