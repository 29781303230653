import React from 'react';
import useStyles from './styles';
import ButtonBlue from '../../../assets/icons/Button_Square_BlueContinue.svg';
import ButtonRed from '../../../assets/icons/Button_Square_RedCross.svg';
import { CommonKeys } from './../../../utils/constants/index';
const UserNotification = ({
	children,
	positiveClick,
	negativeClick,
	isMobile,
	noNegativeClick = false
}) => {
	const classes = useStyles();
	return (
		<div
			id={CommonKeys.NOTIFICATION}
			className={isMobile ? classes.mobileContainer : classes.topContainer}
		>
			<div className={classes.leftText}>{children}</div>
			<div
				className={isMobile ? classes.rightContentMobile : classes.rightContent}
			>
				{negativeClick && !noNegativeClick && (
					<img
						id={CommonKeys.DISMISS}
						src={ButtonRed}
						style={
							positiveClick
								? { marginRight: 5, cursor: 'pointer' }
								: { cursor: 'pointer' }
						}
						onClick={negativeClick}
					></img>
				)}
				{positiveClick && (
					<img
						id={CommonKeys.RIGHT_ANGLE}
						src={ButtonBlue}
						onClick={positiveClick}
						style={{ cursor: 'pointer' }}
						className={isMobile ? {} : classes.positiveButton}
					></img>
				)}
			</div>
		</div>
	);
};

export default UserNotification;
