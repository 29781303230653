import { navigate } from 'gatsby-link';
import React from 'react';
import PrimaryButton from '../../PrimaryButton';
import useStyles from './styles';

const HeaderBanner = ({
	title,
	inline,
	top,
	styles,
	buttonText,
	buttonLink,
	buttonStyles
}) => {
	const classes = useStyles();
	return (
		<>
			<div
				style={styles}
				className={
					top
						? `${classes.topBanner} ${classes.topBannerDefault}`
						: inline
						? `${classes.topBanner} ${classes.topBannerInline}`
						: classes.topBanner
				}
			>
				<div className={classes.topBannerText}>
					{title}
					{buttonText && (
						<>
							<PrimaryButton
								onClick={() => navigate(buttonLink)}
								id="header-banner-button"
								style={buttonStyles}
							>
								{buttonText}
							</PrimaryButton>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default HeaderBanner;
