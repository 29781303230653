import { API, graphqlOperation } from 'aws-amplify';
import { sendLogToServer } from '../../graphql/custom.queries';

const AppSync = () => {};

AppSync.execute = async (query, data) => {
	try {
		API.graphql(graphqlOperation(query, data));
	} catch (error) {
		//even the log failed, possibly appsync is down
		//ideally would serialise the error locally, backoff and try again in a few mins
		//to be implemented at some point in the future
		console.log(error);
	}
};

export const logToServer = (userId, error, action) => {
	let input = {
		memberExternalId: userId,
		userAction: action,
		error: error
	};
	AppSync.execute(sendLogToServer, { input });
};
