import { createUseStyles } from 'react-jss';
import dropdown from '../../assets/icons/Dropdown_Down_icon.svg';

export default createUseStyles(() => ({
	mobileHeaderContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	headerContainer: {
		extend: 'mobileHeaderContainer',
		justifyContent: 'flex-end'
	},
	menuItem: {
		display: 'inline-block',
		textTransform: 'uppercase',
		textDecoration: 'none',
		color: '#0085ca',
		padding: '1rem 1rem',
		'& a': {
			textDecoration: 'none',
			fontSize: 12,
			color: '#0085ca',
			fontWeight: 500
		}
	},
	logo: {
		height: 51,
		imageRendering: '-webkit-optimize-contrast'
	},
	topHeader: {
		width: '100%',
		maxWidth: '1200px !important'
	},
	logoLink: {
		height: 51
	},
	dropDown: {
		position: 'relative',
		display: 'inline-block',
		'&:hover $dropdownContent': {
			display: 'block',
			color: 'blue'
		}
	},
	dropDownHeader: {
		display: 'inline-block',
		textTransform: 'uppercase',
		textDecoration: 'none',
		color: '#0085ca',
		paddingTop: 3,
		fontSize: 12,
		paddingRight: 10,
		fontWeight: 500
	},
	dropdownLink: {
		padding: '12px 16px',
		fontWeight: 500,
		fontSize: '12px',
		display: 'inline-block',
		textTransform: 'uppercase',
		textDecoration: 'none',
		color: '#0085ca',
		width: 'available',
		'min-width': 'fill-available',
		'&:hover': {
			backgroundColor: '#0085ca',
			color: 'white'
		}
	},
	dropDownPlaceholder: {
		display: 'inline-block',
		textTransform: 'uppercase',
		textDecoration: 'none',
		color: '#0085ca',
		padding: '0rem 1rem 0rem 1rem',
		cursor: 'pointer',
		fontSize: 16,
		'&:after': {
			position: 'absolute',
			pointerEvents: 'none',
			content: `url(${dropdown})`,
			right: -10,
			paddingRight: 10
		}
	},
	dropdownContent: {
		display: 'none',
		position: 'absolute',
		backgroundColor: '#f9f9f9',
		minWidth: '200px',
		right: -5,
		boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
		zIndex: 2
	},
	imNew: {
		background: '#e0eef4',
		padding: '2px 10px 2px 10px',
		borderRadius: 2
	},
	stickyHeader: {
		position: 'fixed',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		height: 80,
		zIndex: 100,
		backgroundColor: '#ffffff'
	}
}));
