import { Amplify, API, graphqlOperation, Auth } from 'aws-amplify';
import { logToServer } from '../utils/logger';
import {
	AMAZON_COGNITO_USER_POOLS,
	APPSYNC_CONFIG,
	APIS_CONFIG,
	API_KEY,
	IS_LOGGED_IN
} from './../utils/constants';

const AppSyncService = () => {};

AppSyncService.execute = async (query, data) => {
	try {
		// configure the amplify global object based on the current user being defined
		// if the user exists then send the JWT token used in Amazon Cognito User Pools to make API calls
		// Otherwise, send the Public API key used in AppSync
		if (localStorage.getItem(IS_LOGGED_IN)) {
			Amplify.configure({
				...APPSYNC_CONFIG,
				aws_appsync_authenticationType: AMAZON_COGNITO_USER_POOLS,
				...APIS_CONFIG
			});
		} else {
			Amplify.configure({
				...APPSYNC_CONFIG,
				aws_appsync_authenticationType: API_KEY,
				...APIS_CONFIG
			});
		}

		const result = await API.graphql(graphqlOperation(query, data));
		if (!result) {
			console.debug('No results');
		}

		return {
			data: result.data,
			isSuccessful: true,
			exception: null
		};
	} catch (e) {
		console.error(`Error running query. Details: ${JSON.stringify(e)}`);
		const user = await Auth.currentSession().catch(() => {});
		const externalId = user?.getIdToken().payload.sub;
		e?.errors?.forEach((error) => {
			if (error.errorType === 'Unauthorized') {
				logToServer(
					user ? externalId : 'No Logged in user',
					{
						error,
						info: {
							url: window?.location.href,
							externalId: user ? externalId : undefined,
							groups: user
								? user.getIdToken()?.payload['cognito:groups']
								: undefined
						}
					},
					'Unauthorized request in AppSync'
				);
			}
		});

		//TODO: Add a common log
		return {
			data: null,
			isSuccessful: false,
			exception: e
		};
	}
};

export default AppSyncService;
