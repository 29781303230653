import React from 'react';
import useStyles from './styles';
const Button = ({ text, onClick, styles, id }) => {
	const classes = useStyles();
	return (
		<div
			id={id}
			style={styles}
			className={classes.buttonStyle}
			onClick={onClick}
		>
			{text}
		</div>
	);
};

export default Button;
